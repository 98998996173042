import { LinkAnchor, LinkAnchorProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

export const ExternalLink: FunctionComponent<LinkAnchorProps> = ({
  children,
  ...props
}) => (
  <LinkAnchor
    fontFamily='body'
    fontWeight='normal'
    color='inherit'
    target='_blank'
    rel='noopener noreferrer'
    {...props}
  >
    {children}
  </LinkAnchor>
)
