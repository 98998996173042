import { Flex, mdBumps, Typo, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCancelPolicyAdjustment } from '../../../../hooks/actions/cancel'
import { useNavigateCallback } from '../../../../hooks/navigation'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page } from '../../../../infrastructure/routing'
import { toDateTime } from '../../../../util/date'
import { ActionBar } from '../../../organisms/form'
import { FullPage } from '../../../templates'
import { PolicyPath } from '../PolicyPath'

export interface CancellationConfirmPageProps {
  parent: Page
}

const tKey = TKey('pages.cancel-confirm')

export const CancellationConfirmPage: FunctionComponent<CancellationConfirmPageProps> = ({
  parent,
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const {
    state: { cancellationReason, dateTime },
  } = useLocation()

  const onDismiss = useNavigateCallback(parent.url)
  const onConfirm = useCancelPolicyAdjustment(cancellationReason, dateTime, () => {
    navigate(['..', '..', PolicyPath.CANCEL_CONFIRMATION].join('/'))
  })

  return (
    <FullPage header={t(tKey('header'))} pageId='cancel-confirm'>
      <Flex width={1} variant='raised' px={mdBumps} py={5} mb={xxlBumps}>
        <Typo fontWeight='bold'>{t(tKey('effective.header'))}</Typo>
        <Typo>
          {dateTime
            ? t(tFormats('datetime.medium'), { value: toDateTime(dateTime) })
            : t(tKey('effective.immediate'))}
        </Typo>
      </Flex>

      <ActionBar
        tKey={tKey}
        destructive={true}
        onConfirm={onConfirm.execute}
        onDismiss={onDismiss}
      />
    </FullPage>
  )
}
