import { css } from 'styled-components'

import PublicoHeadlineBold from './assets/fonts/PublicoHeadline-Bold.woff2'
import SourceSansProRegular from './assets/fonts/SourceSansPro-Regular.woff2'
import SourceSansProSemiBold from './assets/fonts/SourceSansPro-SemiBold.woff2'
import UKNumberPlateEot from './assets/fonts/uknumberplate/UKNumberPlate.eot'
import UKNumberPlateWoff2 from './assets/fonts/uknumberplate/UKNumberPlate.woff2'

const fonts = css`
  @font-face {
    font-family: 'PublicoHeadline';
    src: url('${PublicoHeadlineBold as never}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('${SourceSansProRegular as never}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('${SourceSansProSemiBold as never}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateEot as never}') format('eot'),
      url('${UKNumberPlateEot as never}') format('eot');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateWoff2 as never}') format('woff2'),
      url('${UKNumberPlateWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`

export default fonts
