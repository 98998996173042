import { Icon, wideContainerWidths, WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import axaMobilaktivreiseschutz from './assets/images/axa-mobilaktivreiseschutz.jpg'
import axaWallboxschutz from './assets/images/axa-wallboxschutz.jpg'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const colors = {
  white: '#FFFFFF',
  primary700: '#010143',
  primary600: '#00008f',
  primary50: '#f2f2f9',
  secondary600: '#d24723',
  gray900: '#111b1d',
  gray800: '#343c3d',
  gray600: '#5f5f5f',
  gray400: '#757575',
  gray200: '#999999',
  gray150: '#cccccc',
  gray100: '#e5e5e5',
  gray75: '#f0f0f0',
  gray50: '#fafafa',
  alert700: '#9e1a29',
  alert600: '#c91432',
  alert50: '#fae7ea',
  success600: '#138682',
  success50: '#e3f0f0',
  warning600: '#c94e14',
  warning50: '#f9eae3',
  info600: '#4976ba',
  info50: '#e4eaf5',
}

const typoSizes = {
  xxl: {
    fontSize: ['28px', '32px', '40px', '48px'],
    lineHeight: ['36px', '40px', '48px', '56px'],
    fontFamily: 'PublicoHeadline, serif',
  },
  xl: {
    fontSize: ['24px', '28px', '32px', '32px'],
    lineHeight: ['32px', '36px', '40px', '40px'],
    fontFamily: 'PublicoHeadline, serif',
  },
  lg: {
    fontSize: ['20px', '22px', '24px'],
    lineHeight: ['28px', '30px', '32px'],
    fontFamily: 'PublicoHeadline, serif',
  },
  md: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['24px', '24px', '28px'],
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '16px',
  },
}

export const axaTheme: WriskTheme = {
  // Common
  name: 'AXA',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //    0    0px
    '0.25rem', // 1    4px
    '0.5rem', //  2    8px
    '0.75rem', // 3   12px
    '1.0rem', //  4   16px
    '1.25rem', // 5   20px
    '1.5rem', //  6   24px
    '1.75rem', // 7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', //  10   48px
    '3.5rem', //  11   56px
    '4.0rem', //  12   64px
    '4.5rem', //  13   72px
    '5.0rem', //  14   80px
  ],
  radii: [0, 0, 0, 0],
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  fonts: {
    header: 'SourceSansPro, sans-serif',
    body: 'SourceSansPro, sans-serif',
    navbar: 'SourceSansPro, sans-serif',
    buttons: 'SourceSansPro, sans-serif',
    input: 'SourceSansPro, sans-serif',
    licencePlate: 'UKLicencePlate, sans-serif',
  },
  typoSizes,
  shadows: {
    default: `0 0 0 1px ${colors.gray150}`,
    input: `0 0 0 1px ${colors.primary600}`,
    inset: `inset -8px 0 0 0 ${colors.white}, inset 8px 0 0 0 ${colors.white}`,
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: `inset 0 0 0 1px ${colors.gray150}`,
    radioStandalone: `inset 0 0 0 1px ${colors.gray150}`,
    radioHover: `inset 0 0 0 2px ${colors.primary600}`,
    radioChecked: `inset 0 0 0 2px ${colors.primary600}`,
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['72px'],
  },
  colors: {
    chalk: colors.white,
    background: colors.gray50,
    body: colors.gray800,
    bodySecondary: colors.gray600,
    hyperlink: colors.primary600,
    focus: colors.primary600,
    hover: colors.gray75,

    primaryAccent: colors.primary600,
    secondaryAccent: colors.primary600,

    foregroundPromoBanner: colors.white,
    backgroundPromoBanner: colors.primary700,

    foregroundPromoActivated: colors.white,
    backgroundPromoActivated: colors.primary700,

    textOnNavigation: colors.gray800,
    surfaceNavigation: colors.white,

    textInfo: colors.info600,
    surfaceInfoSubdued: colors.info50,
    surfaceInfo: colors.info600,

    textCritical: colors.alert600,
    textOnCritical: colors.alert600,
    surfaceCritical: colors.alert600,
    surfaceCriticalHovered: colors.alert50,
    surfaceCriticalSubdued: colors.alert50,

    textWarning: colors.warning600,
    textOnWarning: colors.warning600,
    surfaceWarning: colors.warning600,
    surfaceWarningSubdued: colors.warning50,

    textHighlight: colors.gray600,
    textOnHighlight: colors.gray600,
    surfaceHighlight: colors.gray100,
    surfaceHighlightSubdued: colors.gray75,

    textSuccess: colors.success600,
    textOnSuccess: colors.success600,
    surfaceSuccess: colors.success600,
    surfaceSuccessSubdued: colors.success50,

    border: colors.gray100,
    divider: colors.gray100,
    inactive: colors.gray400,
    placeholder: colors.gray200,

    textDisabled: colors.gray600,
    surfaceDisabled: colors.gray100,

    textFooter: colors.white,
    dividerFooter: 'rgba(255, 255, 255, .25)',
    surfaceFooter: colors.info600,

    radioCheckedBackground: colors.white,
    radioCheckedForeground: colors.primary600,

    dragBoxBorderActive: colors.primary600,
    dragBoxBorderDefault: colors.gray100,

    progressBackgroundColor: colors.gray75,
    progressBarColor: colors.primary600,
  },
  // todo
  proposalImages: {
    'vwfs-wallboxschutz': axaWallboxschutz as string,
    'vwfs-mobilaktivreiseschutz': axaMobilaktivreiseschutz as string,
  },
  containerStyle: {
    default: {},
    raised: {
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderColor: 'border',
      borderWidth: '1px',
    },
    prominent: {},
  },
  // Components
  Button: {
    base: {
      textTransform: 'uppercase',
      letterSpacing: '0.0625rem',
      transition: 'all 400ms ease',
    },
    layout: {
      default: {
        px: 6,
        py: 4,
        fontSize: '14px',
        lineHeight: '16px',
      },
      fixed: {
        px: 6,
        py: 4,
        fontSize: '14px',
        lineHeight: '16px',
        width: '85%',
        maxWidth: '262px',
      },
      small: {
        px: 4,
        py: 3,
        fontSize: '12px',
        lineHeight: '12px',
      },
    },
  },
  PrimaryButton: {
    variants: {
      default: {
        bg: colors.primary600,
        color: colors.white,
        '&:hover:not([disabled])': {
          bg: colors.primary700,
        },
      },
      inverted: {},
      critical: {
        bg: colors.alert600,
        color: colors.white,
        '&:hover:not([disabled])': {
          bg: colors.alert700,
        },
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        boxShadow: `inset 0 0 0 0.1em ${colors.primary600}`,
        bg: colors.white,
        color: colors.primary600,
        '&:hover:not([disabled])': {
          bg: colors.primary600,
          color: colors.white,
        },
      },
      inverted: {},
    },
  },
  TertiaryButton: {
    variants: {
      default: {
        boxShadow: `inset 0 0 0 0.1em ${colors.gray100}`,
        bg: colors.white,
        color: colors.gray100,
        '&:hover:not([disabled])': {
          bg: colors.gray100,
          color: colors.white,
        },
      },
      inverted: {},
    },
  },
  IconButton: {
    variants: {
      default: {
        ':hover': {
          backgroundColor: 'hover',
        },
      },
      primary: {
        bg: colors.primary600,
        color: colors.white,
        '&:hover:not([disabled])': {
          bg: colors.primary700,
        },
        [Icon]: {
          path: {
            fill: colors.white,
          },
          ellipse: {
            fill: colors.white,
          },
        },
      },
    },
  },
  Heading: {
    default: {},
    h1: {},
    h2: {},
    h3: {},
    h4: {},
  },
  Typo: {
    default: {},
    heading: {},
    buttons: {},
  },
  TabAnchor: {
    active: {
      py: 4,
      color: colors.primary600,
      borderColor: colors.secondary600,
      borderBottomWidth: '3px',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      '&:hover': {
        color: colors.primary700,
      },
    },
    inactive: {
      py: 4,
      color: colors.primary600,
      borderColor: 'transparent',
      borderBottomWidth: '3px',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      '&:hover': {
        color: colors.primary700,
        borderColor: colors.secondary600,
      },
    },
  },
  LinkAnchor: {
    standalone: {
      position: 'relative',
      textTransform: 'uppercase',
      color: colors.primary600,
      borderColor: 'transparent',
      borderBottomWidth: 0,
      letterSpacing: '0.0625rem',
      '&:hover': {
        color: colors.primary700,
        boxShadow: `inset 0 -1px 0 0 ${colors.primary700}`,
      },
    },
    inline: {
      borderColor: 'transparent',
      boxShadow: `inset 0 -1px 0 0`, // inherit the text color
      borderBottomWidth: 0,
      '&:hover': {
        color: colors.primary600,
        boxShadow: `inset 0 -1px 0 0 ${colors.primary600}`,
      },
    },
  },
  FooterAnchor: {
    default: {
      '&:hover': {
        boxShadow: `inset 0 -1px 0 0 ${colors.white}`,
      },
    },
  },
  LogoNav: {
    default: {},
  },
  Logo: {
    default: {
      height: ['48px'],
    },
  },
  NavBar: {
    default: {
      width: wideContainerWidths,
      minWidth: wideContainerWidths,
      mx: 'auto',
      px: [0, 0, 0, 0],
    },
  },
  Navigation: {
    default: {
      borderBottomColor: (theme: WriskTheme) => theme.colors.border,
      borderBottomWidth: '1px',
    },
  },
  HelpText: {
    default: {
      textTransform: 'uppercase',
    },
  },
  NavAnchor: {
    default: {
      height: '100%',
      color: colors.primary600,
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      borderBottomWidth: '3px',
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      '&:hover': {
        color: colors.primary700,
        borderBottomColor: colors.secondary600,
        backgroundColor: 'transparent',
      },
      [Icon]: {
        path: {
          fill: colors.primary600,
        },
        ellipse: {
          fill: colors.primary600,
        },
      },
    },
    inverted: {
      fontWeight: 'bold',
    },
  },
  StyledPremium: {
    default: {},
    inverted: {
      bg: 'transparent',
      fontFamily: 'PublicoHeadline, serif',
    },
  },
  Status: {
    default: {
      p: 2,
      bg: 'surfaceHighlightSubdued',
      color: 'textHighlight',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    info: {
      p: 2,
      bg: 'surfaceInfoSubdued',
      color: 'textInfo',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    success: {
      p: 2,
      bg: 'surfaceSuccessSubdued',
      color: 'textSuccess',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    warning: {
      p: 2,
      bg: 'surfaceWarningSubdued',
      color: 'textWarning',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    critical: {
      p: 2,
      bg: 'surfaceCriticalSubdued',
      color: 'textCritical',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
  },
}
